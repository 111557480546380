import { GetterTree, ActionTree, MutationTree } from 'vuex';
import {
  BankAccount,
  BankAccountState,
  BankParams
} from '@/store/interfaces/bankAccount';
// Establece los types de los modulos store
import bankType from '@/store/types/bankAccount';
import { http } from '@/util/HTTP';
import { AxiosError, AxiosResponse } from 'axios';
import { TeipNotifications } from '@/util/teipNotifications';
import { DEFAULT_EMPTY_VALUE } from '@/util/consts';
import { responseCollection, responseSingle } from '@/store/interfaces/json-api/generics';
import { allowAny } from 'teip-components';
const notification = new TeipNotifications();

const namespaced = true;

const initialState = (): BankAccountState => {
  return {
    account: {
      name: DEFAULT_EMPTY_VALUE,
      bank_id: DEFAULT_EMPTY_VALUE,
      account_number: DEFAULT_EMPTY_VALUE,
      account_type: 'current',
      account_holder: DEFAULT_EMPTY_VALUE,
      billing_address: DEFAULT_EMPTY_VALUE,
      billing_type: 'final_consumer',
      contact: {
        email: DEFAULT_EMPTY_VALUE,
        id: DEFAULT_EMPTY_VALUE,
        name: DEFAULT_EMPTY_VALUE,
        phone: DEFAULT_EMPTY_VALUE,
        last_name: DEFAULT_EMPTY_VALUE,
      },
      contact_id: DEFAULT_EMPTY_VALUE,
    },
    deleteButton: false,
    banksList: [],
  };
};

const state = initialState;
const getters: GetterTree<BankAccountState, allowAny> = {
  [bankType.getters.GET_BANK_ACCOUNT]: state => {
    return state.account;
  },
  [bankType.getters.GET_SHOW_DELETE_BUTTON]: state => {
    return state.deleteButton;
  },
  [bankType.getters.GET_BANKS_LIST]: state => {
    return state.banksList;
  },
};
const mutations: MutationTree<BankAccountState> = {
  [bankType.mutations.SET_BANK_ACCOUNT]: (
    state,
    account: responseSingle<BankAccount>
  ) => {
    if (account.data) {
      state.deleteButton = true;
      state.account = account.data;
    } else {
      state.account = initialState().account;
      state.deleteButton = false;
    }
  },
  [bankType.mutations.SET_SHOW_DELETE_BUTTON]: (state, show: boolean) => {
    state.deleteButton = show;
  },
  [bankType.mutations.SET_BANKS_LIST]: (
    state,
    banks: responseCollection<BankParams>
  ) => {
    state.banksList = banks.data;
  },
};
const actions: ActionTree<BankAccountState, allowAny> = {
  [bankType.actions.BANK_ACCOUNT]: ({ commit, }, company_id: string) => {
    return new Promise((resolve, reject) => {
      http
        .get(`backoffice/companies/${company_id}/bank_account`)
        .then((res: AxiosResponse) => {
          commit(bankType.mutations.SET_BANK_ACCOUNT, res.data);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [bankType.actions.SAVE_BANK_ACCOUNT]: ({ commit, }, { company_id, data, }) => {
    return new Promise((resolve, reject) => {
      http
        .post(`backoffice/companies/${company_id}/bank_account`, { data: data, })
        .then((res: AxiosResponse) => {
          commit(bankType.mutations.SET_BANK_ACCOUNT, res.data);
          commit(bankType.mutations.SET_SHOW_DELETE_BUTTON, true);
          notification.success('Nueva cuenta bancaria agregada');
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [bankType.actions.DELETE_BANK_ACCOUNT]: ({ commit, }, company_id) => {
    return new Promise((resolve, reject) => {
      http
        .delete(`backoffice/companies/${company_id}/bank_account`)
        .then((res: AxiosResponse) => {
          commit(bankType.mutations.SET_BANK_ACCOUNT, { data: initialState().account, });
          commit(bankType.mutations.SET_SHOW_DELETE_BUTTON, false);
          notification.success('Cuenta eliminada satisfactoriamente');
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [bankType.actions.BANKS_LIST]: ({ commit, }) => {
    return new Promise((resolve, reject) => {
      http
        .get('backoffice/banks')
        .then((res: AxiosResponse) => {
          commit(bankType.mutations.SET_BANKS_LIST, res.data);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
