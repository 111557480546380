
































































import flatPicker from '@/components/FlatPickr/FlatPicker.vue';
import company from '@/store/types/company';
import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'BusinessRegistration',
  components: {
    flatPicker,
  },
  props: {
    rules: {
      type: String,
      required: false,
      default: () => '',
    },
  },
  computed: {
    ...mapGetters({
      getNewCompanyParams: `companyModule/${company.getters.GET_NEW_COMPANY_PARAMS}`,
    }),
  },
});
