




































import { NakaBranchConfiguration } from '@/store/interfaces/branchs';
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'NakaBranchConfigurationForm',
  props: {
    naka: {
      type: Object as PropType<NakaBranchConfiguration>,
      required: true,
    },
  },
  computed: {
    nakaBranchConfiguration: {
      get(): NakaBranchConfiguration {
        return this.naka;
      },
      set(value: NakaBranchConfiguration) {
        this.$emit('update:naka', value);
      },
    },
    nakaBranchConfigurationHasValues: {
      get(): boolean {
        return Object.values(this.nakaBranchConfiguration).some(
          (value) => value !== ''
        );
      },
    },
    nakaConfigurationShouldBeRequired(): 'required' | '' {
      return this.nakaBranchConfigurationHasValues ? 'required' : '';
    },
  },
});
