









































































import { phoneMask } from '@/const/phoneNumber';
import companyTypes from '@/store/types/company';
import locationType from '@/store/types/country';
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';

export default Vue.extend({
  name: 'EnterpriseAddress',
  data: () => ({
    phoneMask,
  }),
  computed: {
    ...mapGetters({
      getListState: `country/${locationType.getters.GET_STATE}`,
      getNewCompanyParams: `companyModule/${companyTypes.getters.GET_NEW_COMPANY_PARAMS}`,
    }),
  },
  methods: {
    ...mapActions({
      ListState: `country/${locationType.actions.STATE_LIST}`,
    }),
  },
  async mounted(): Promise<void> {
    await this.ListState();
  },
});
