export default {
  mutations: {
    SET_CURRENCIES: 'SET_CURRENCIES',
    SET_TRANSACTION_FEES: 'SET_TRANSACTION_FEES',
    SET_EXCHANGES_FEES: 'SET_EXCHANGES_FEES',
  },
  getters: {
    GET_CURRENCIES: 'GET_CURRENCIES',
    GET_TRANSACTION_FEES: 'GET_TRANSACTION_FEES',
    GET_EXCHANGES_FEES: 'GET_EXCHANGES_FEES',
  },
  actions: {},
};
