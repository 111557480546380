/* eslint-disable @typescript-eslint/no-explicit-any */

import { GetterTree, ActionTree, MutationTree } from 'vuex';
import {
  ContactState,
  ContactParams,
  ContactKindParams
} from '@/store/interfaces/contact';
// Establece los types de los modulos store
import contactType from '@/store/types/contact';
import { http } from '@/util/HTTP';
import { AxiosResponse, AxiosError } from 'axios';
import { responseCollection } from '../interfaces/json-api/generics';

const namespaced = true;

const initialState = (): ContactState => {
  return {
    contact_kind: {
      data: [],
      pagination: {},
    },
    contact_by_company: {
      data: [],
      pagination: {},
    },
    contact_by_branchs: {
      data: [],
      pagination: {},
    },
    single_contact: {
      name: '',
      email: '',
      phone: '',
      last_name: '',
    },
  };
};

const entityAllowed = (entity: string): void => {
  const entityAllowed = ['companies', 'branches'];
  if (!entityAllowed.includes(entity)) {
    throw Error(
      'entity only allowed strings equals to "companies", and "branches"'
    );
  }
};

const state: ContactState = initialState();

const getters: GetterTree<ContactState, any> = {
  [contactType.getters.GET_CONTACT_KIND]: state => {
    return state.contact_kind;
  },
  [contactType.getters.GET_CONTACT_BY_COMPANY]: state => {
    return state.contact_by_company;
  },
  [contactType.getters.GET_CONTACT_BY_BRANCHES]: state => {
    return state.contact_by_branchs;
  },
  [contactType.getters.GET_SINGLE_CONTACT]: state => {
    return state.single_contact;
  },
};
const mutations: MutationTree<ContactState> = {
  [contactType.mutations.SET_CONTACT_KIND]: (
    state,
    contact_kind: responseCollection<ContactKindParams>
  ) => {
    state.contact_kind.data = contact_kind.data;
    state.contact_kind.pagination = contact_kind.meta.pagination;
  },
  [contactType.mutations.SET_CONTACT_BY_COMPANY]: (
    state,
    contact_by_company: responseCollection<ContactParams>
  ) => {
    state.contact_by_company.data = contact_by_company.data;
    state.contact_by_company.pagination = contact_by_company.meta.pagination;
  },
  [contactType.mutations.SET_CONTACT_BY_BRANCHES]: (
    state,
    contact_by_branchs: responseCollection<ContactParams>
  ) => {
    state.contact_by_branchs.data = contact_by_branchs.data;
    state.contact_by_branchs.pagination = contact_by_branchs.meta.pagination;
  },
  [contactType.mutations.SET_SAVE_CONTACT_BY_BRANCH]: (
    state,
    contact_by_branchs
  ) => {
    state.contact_by_branchs.data.unshift(contact_by_branchs.data);
  },
  [contactType.mutations.SET_SINGLE_CONTACT]: (
    state,
    contact: ContactParams
  ) => {
    state.single_contact = contact;
  },
  [contactType.mutations.SET_SAVE_CONTACT_BY_COMPANY]: (
    state,
    contact_by_company
  ) => {
    state.contact_by_company.data.unshift(contact_by_company.data);
  },
  [contactType.mutations.SET_DELETE_DRAFT_CONTACT]: (
    state,
    { contact_id, entity, }
  ) => {
    if (entity === 'companies') {
      const filterContactByCompany = state.contact_by_company.data.filter(
        (filter: ContactParams) => filter.id !== contact_id
      );
      state.contact_by_company.data = filterContactByCompany;
    } else {
      const filterContactByBranch = state.contact_by_branchs.data.filter(
        (filter: ContactParams) => filter.id !== contact_id
      );
      state.contact_by_branchs.data = filterContactByBranch;
    }
  },
};
const actions: ActionTree<ContactState, any> = {
  [contactType.actions.LIST_CONTACT_KIND]: ({ commit, }) => {
    return new Promise((resolve, reject) => {
      http
        .get('backoffice/contact_kinds')
        .then((res: AxiosResponse) => {
          commit(contactType.mutations.SET_CONTACT_KIND, res.data);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [contactType.actions.SAVE_CONTACT]: (
    { commit, },
    { entity_id, data, entity, }
  ) => {
    entityAllowed(entity);
    return new Promise((resolve, reject) => {
      http
        .post(`backoffice/${entity}/${entity_id}/contacts`, { data: data, })
        .then((res: AxiosResponse) => {
          if (entity === 'companies') {
            commit(contactType.mutations.SET_SAVE_CONTACT_BY_COMPANY, res.data);
          } else {
            commit(contactType.mutations.SET_SAVE_CONTACT_BY_BRANCH, res.data);
          }
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [contactType.actions.LIST_CONTACT_BY_COMPANY]: ({ commit, }, { company_id, }) => {
    return new Promise((resolve, reject) => {
      http
        .get(`backoffice/companies/${company_id}/contacts`)
        .then((res: AxiosResponse) => {
          commit(contactType.mutations.SET_CONTACT_BY_COMPANY, res.data);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [contactType.actions.LIST_CONTACT_BY_BRANCH]: ({ commit, }, { branch_id, }) => {
    return new Promise((resolve, reject) => {
      http
        .get(`backoffice/branches/${branch_id}/contacts`)
        .then((res: AxiosResponse) => {
          commit(contactType.mutations.SET_CONTACT_BY_BRANCHES, res.data);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [contactType.actions.UPDATE_CONTACT]: (_, { contact_id, data, }) => {
    return new Promise((resolve, reject) => {
      http
        .put(`backoffice/contacts/${contact_id}`, { data, })
        .then((res: AxiosResponse) => {
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [contactType.actions.DELETE_DRAFT_CONTACT]: (
    { commit, },
    { contact_id, entity, }
  ) => {
    entityAllowed(entity);
    return new Promise((resolve, reject) => {
      http
        .delete(`backoffice/contacts/${contact_id}`)
        .then((res: AxiosResponse) => {
          commit(contactType.mutations.SET_DELETE_DRAFT_CONTACT, {
            contact_id,
            entity,
          });
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [contactType.actions.SINGLE_CONTACT]: ({ commit, }, contact_id) => {
    return new Promise((resolve, reject) => {
      http
        .get(`backoffice/contacts/${contact_id}`)
        .then((res: AxiosResponse) => {
          commit(contactType.mutations.SET_SINGLE_CONTACT, res.data.data);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
