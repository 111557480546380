






















































































































































import currencyMask from '@/util/CurrencyMask';
import contactTypes from '@/store/types/contact';
import locationType from '@/store/types/country';
import branchType from '@/store/types/branchs';
import { AxiosError, AxiosResponse } from 'axios';
import { TeipNotifications } from '@/util/teipNotifications';
import {
  BranchParams,
  NakaBranchConfiguration,
  newBranchParams
} from '@/store/interfaces/branchs';
import company from '@/store/types/company';
import root from '@/store/types/root';
import company_commerce from '@/store/types/company_commerce';
import { ContactKindParams } from '@/store/interfaces/contact';
import copyObj from '@/util/copyObj';
import { ValidationProvider } from 'vee-validate';
import { allowAny } from 'teip-components';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Vue from 'vue';
import { phoneMask } from '@/const/phoneNumber';
import NakaBranchConfigurationForm from './forms/NakaBranchConfigurationForm.vue';

export default Vue.extend({
  components: { NakaBranchConfigurationForm, },
  name: 'NewBranch',
  data: () => ({
    phoneMask,
    message: '',
    disabled: false,
    mask: currencyMask,
    company_id: '',
    clean: false,
    branch: {
      address: {
        description: '',
        phone: '',
        state_code: '',
      },
      contacts: [
        {
          contact_kind_id: '',
          email: '',
          name: '',
          last_name: '',
          phone: '',
        }
      ],
      name: '',
      status: 'inactive',
      naka_branch_configuration: {
        place_id: '',
        language: '',
        source_timezone: '',
      },
    } as newBranchParams,
    nakaBranchConfiguration: {
      place_id: '',
      source_timezone: '',
      language: '',
    } as NakaBranchConfiguration,
    loading: true,
    contactKind: [] as ContactKindParams[],
  }),
  computed: {
    ...mapGetters({
      getProcess: root.getters.GET_START_PROCESS,
      getContactKind: `contactModule/${contactTypes.getters.GET_CONTACT_KIND}`,
      getListState: `country/${locationType.getters.GET_STATE}`,
      getCommerceByCompany: `companyCommerceModule/${company_commerce.getters.GET_COMPANY_COMMERCE_BY_COMPANY}`,
      getNewBranchParams: `commerceModule/branches/${branchType.getters.GET_NEW_BRANCH_PARAMS}`,
      getCompaniesByCommerce: `companyModule/${company.getters.GET_COMPANIES_BY_COMMERCE}`,
    }),
    nakaBranchConfigurationHasValues(): boolean {
      return Object.values(this.nakaBranchConfiguration).some(
        (value) => value !== ''
      );
    },
  },
  async mounted() {
    await this.ContactKindList();
    await this.ListState();
    await this.companiesByCommerce({
      query: '',
      commerce_id: this.$route.params.commerce_id,
    });
    this.setContactKind();
    this.loading = false;
  },
  created() {
    this.restoreBranchFromVuexStore();
  },
  methods: {
    ...mapActions({
      ContactKindList: `contactModule/${contactTypes.actions.LIST_CONTACT_KIND}`,
      ListState: `country/${locationType.actions.STATE_LIST}`,
      CommerceByCompanyList: `companyCommerceModule/${company_commerce.actions.COMPANY_COMMERCE_BY_COMPANY}`,
      saveBranch: `commerceModule/branches/${branchType.actions.SAVE_BRANCH}`,
      companiesByCommerce: `companyModule/${company.actions.COMPANIES_BY_COMMERCE_LIST}`,
    }),
    ...mapMutations({
      setNewBranchParams: `commerceModule/branches/${branchType.mutations.SET_NEW_BRANCH_PARAMS}`,
    }),
    restoreBranchFromVuexStore(): void {
      this.branch.name = this.getNewBranchParams.name;
      this.branch.address.description =
        this.getNewBranchParams.address.description;
      this.branch.address.phone = this.getNewBranchParams.address.phone;
      this.branch.address.state_code =
        this.getNewBranchParams.address.state_code;

      this.branch.contacts[0].contact_kind_id =
        this.getNewBranchParams.contacts[0].contact_kind_id;

      this.branch.contacts[0].email = this.getNewBranchParams.contacts[0].email;
      this.branch.contacts[0].name = this.getNewBranchParams.contacts[0].name;
      this.branch.contacts[0].last_name =
        this.getNewBranchParams.contacts[0].last_name;
      this.branch.contacts[0].phone = this.getNewBranchParams.contacts[0].phone;
      this.branch.naka_branch_configuration =
      this.getNewBranchParams.naka_branch_configuration;

      this.nakaBranchConfiguration = {
        place_id: this.getNewBranchParams.naka_branch_configuration.place_id,
        language: this.getNewBranchParams.naka_branch_configuration.language,
        source_timezone: this.getNewBranchParams.naka_branch_configuration.source_timezone,
      };
    },

    cancel(): void {
      this.$emit('cancel', true);
      this.$store.commit(
        `commerceModule/branches/${branchType.mutations.SET_EMPTY_NEW_BRANCH_PARAMS}`
      );
    },

    async firstFormSubmit(): Promise<void> {
      const notification = new TeipNotifications();

      const dataToSend = {
        company_commerce_id: this.getCommerceByCompany.data[0].id,
        data: {
          name: this.branch.name,
          status: this.branch.status,
          address: {
            ...this.branch.address,
            phone: this.branch.address.phone.replace(/ /g, ''),
          },
          contacts: [
            {
              ...this.branch.contacts[0],
              phone: this.branch.contacts[0].phone.replace(/ /g, ''),
            }
          ],
          naka_branch_configuration: this.nakaBranchConfigurationHasValues ? this.nakaBranchConfiguration : null,
        },
      };

      this.saveBranch(dataToSend)
        .then((result: AxiosResponse) => {
          notification.success('Sucursal guardada correctamente.');
          this.clean = true;
          this.$emit('created', result.data.data);
          this.setContactKind();
          (
            this.$refs.formNewBranch as InstanceType<typeof ValidationProvider>
          ).reset();
        })
        .catch((error: AxiosError) => {
          if (error.response && error.response?.status === 422) {
            const setErrors: Record<string, allowAny> = [];
            Object.entries(error.response.data.error).forEach(
              ([key, value]) => {
                const inputKeyName = key.split('.')[0];
                const inputName = key.split('.')[1];

                if (inputKeyName === 'contacts' && inputName === 'phone') {
                  setErrors.contact_phone = value;
                } else if (
                  inputKeyName === 'contacts' &&
                  inputName === 'name'
                ) {
                  setErrors.contact_name = value;
                } else if (inputName) {
                  setErrors[inputName] = value;
                } else {
                  setErrors[inputKeyName] = value;
                }
              }
            );
            (
              this.$refs.formNewBranch as InstanceType<
                typeof ValidationProvider
              >
            ).setErrors(setErrors as string[]);
          } else {
            notification.warning('Algo ha salido mal :(');
          }
        })
        .finally(() => {
          this.clean = false;
        });
    },

    setContactKind(): void {
      this.contactKind = copyObj(this.getContactKind.data);
      this.branch.contacts[0].contact_kind_id = this.contactKind[5].id;
      this.contactKind.shift();
    },
  },
  watch: {
    branch: {
      deep: true,
      handler(val: BranchParams): void {
        setTimeout(() => {
          this.setNewBranchParams(val);
        }, 500);
      },
    },
    company_id(val: string, oldVal: string): void {
      if (val !== oldVal && val !== '') {
        this.CommerceByCompanyList({
          query: '',
          company_id: val,
          commerce_id: this.$route.params.commerce_id,
        }).then((res: AxiosResponse) => {
          if (res.data.data.length === 0) {
            this.disabled = true;
            this.message = `El ${this.$t(
              'menu.commerce.singular.informal'
            )} al cual le intentas agregar una
            nueva sucursal no pertenece a esta ${this.$t(
    'menu.company.singular.informal'
  )}.
            Primero agrégale un ${this.$t('menu.commerce.singular.informal')}
            a esta ${this.$t(
    'menu.company.singular.informal'
  )} para agregarle una sucursal`;
          } else {
            this.disabled = false;
            this.message = '';
          }
        });
      }
    },
  },
});
