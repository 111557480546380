var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading)?_c('div',{staticClass:"form-row"},[_c('b-col',{attrs:{"md":"4"}},[_c('teip-select',{attrs:{"select-options":_vm.fiatCurrencies,"label-option":"name","clearable":"","rules":_vm.rules,"label":"Moneda fiat activa","name":"fiat_currency_active_id"},model:{value:(
        _vm.getNewCompanyParams.naka_company_configuration.fiat_currency_active_id
      ),callback:function ($$v) {_vm.$set(_vm.getNewCompanyParams.naka_company_configuration, "fiat_currency_active_id", $$v)},expression:"\n        getNewCompanyParams.naka_company_configuration.fiat_currency_active_id\n      "}})],1),_c('b-col',{attrs:{"md":"4"}},[_c('teip-select',{attrs:{"select-options":_vm.fiatCurrencies,"label-option":"name","clearable":"","rules":_vm.rules,"label":"Moneda fiat pendiente","name":"fiat_currency_pending_id"},model:{value:(
        _vm.getNewCompanyParams.naka_company_configuration
          .fiat_currency_pending_id
      ),callback:function ($$v) {_vm.$set(_vm.getNewCompanyParams.naka_company_configuration
          , "fiat_currency_pending_id", $$v)},expression:"\n        getNewCompanyParams.naka_company_configuration\n          .fiat_currency_pending_id\n      "}})],1),_c('b-col',{attrs:{"md":"4"}},[_c('teip-select',{attrs:{"select-options":_vm.fiatCurrencies,"rules":_vm.rules,"label-option":"name","clearable":"","label":"Moneda de liquidación activa","name":"settlement_currency_active_id"},model:{value:(
        _vm.getNewCompanyParams.naka_company_configuration
          .settlement_currency_active_id
      ),callback:function ($$v) {_vm.$set(_vm.getNewCompanyParams.naka_company_configuration
          , "settlement_currency_active_id", $$v)},expression:"\n        getNewCompanyParams.naka_company_configuration\n          .settlement_currency_active_id\n      "}})],1),_c('b-col',{attrs:{"md":"4"}},[_c('teip-select',{attrs:{"select-options":_vm.fiatCurrencies,"label-option":"name","clearable":"","label":"Moneda de liquidación pendiente","rules":_vm.rules,"name":"settlement_currency_pending_id"},model:{value:(
        _vm.getNewCompanyParams.naka_company_configuration
          .settlement_currency_pending_id
      ),callback:function ($$v) {_vm.$set(_vm.getNewCompanyParams.naka_company_configuration
          , "settlement_currency_pending_id", $$v)},expression:"\n        getNewCompanyParams.naka_company_configuration\n          .settlement_currency_pending_id\n      "}})],1),_c('b-col',{attrs:{"md":"4"}},[_c('teip-select',{attrs:{"select-options":_vm.exchangesFees,"label-option":"name","rules":_vm.rules,"clearable":"","label":"Tarifa de cambio","name":"exchange_fee_id"},model:{value:(_vm.getNewCompanyParams.naka_company_configuration.exchange_fee_id),callback:function ($$v) {_vm.$set(_vm.getNewCompanyParams.naka_company_configuration, "exchange_fee_id", $$v)},expression:"getNewCompanyParams.naka_company_configuration.exchange_fee_id"}})],1),_c('b-col',{attrs:{"md":"4"}},[_c('teip-select',{attrs:{"select-options":_vm.transactionsFees,"label-option":"name","clearable":"","label":"Tafifa de transacción","rules":_vm.rules,"name":"transaction_fee_id"},model:{value:(
        _vm.getNewCompanyParams.naka_company_configuration.transaction_fee_id
      ),callback:function ($$v) {_vm.$set(_vm.getNewCompanyParams.naka_company_configuration, "transaction_fee_id", $$v)},expression:"\n        getNewCompanyParams.naka_company_configuration.transaction_fee_id\n      "}})],1)],1):_c('loading-panel')}
var staticRenderFns = []

export { render, staticRenderFns }