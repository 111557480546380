/* eslint-disable @typescript-eslint/no-explicit-any */

import { RootState } from '@/store/interfaces/rootState';
import { ActionTree, GetterTree, MutationTree } from 'vuex';

// Establece los types de los modulos store
import { Collections, NakaCollectionState } from '../interfaces/NakaCollections';
import NakaCollection from '../types/NakaCollection';

const namespaced = true;

const initialState = (): NakaCollectionState => {
  return {
    transactionFees: [],
    exchangeFees: [],
    currencies: [],
  };
};

const state: NakaCollectionState = initialState();

const getters: GetterTree<NakaCollectionState, RootState> = {
  [NakaCollection.getters.GET_CURRENCIES]: state => {
    return state.currencies;
  },
  [NakaCollection.getters.GET_EXCHANGES_FEES]: state => {
    return state.exchangeFees;
  },
  [NakaCollection.getters.GET_TRANSACTION_FEES]: state => {
    return state.transactionFees;
  },
};
const mutations: MutationTree<NakaCollectionState> = {
  [NakaCollection.mutations.SET_CURRENCIES]: (state, currencies: Collections[]) => {
    state.currencies = currencies;
  },
  [NakaCollection.mutations.SET_EXCHANGES_FEES]: (state, countries: Collections[]) => {
    state.exchangeFees = countries;
  },
  [NakaCollection.mutations.SET_TRANSACTION_FEES]: (state, countries: Collections[]) => {
    state.transactionFees = countries;
  },
};
const actions: ActionTree<NakaCollectionState, RootState> = {};

export default {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
