// Sentry
import { setUser, configureScope } from '@sentry/vue';

import Vue from 'vue';
import Vuex, { GetterTree, MutationTree } from 'vuex';
import commerceModule from '@/store/modules/commerce';
import configurationModule from '@/store/modules/configurations';
import intermediariesModule from '@/store/modules/intermediaries';
import companyModule from '@/store/modules/company';
import documentModule from '@/store/modules/document';
import uploadImage from '@/store/modules/uploadimage';
import authModule from '@/store/modules/auth';
import liquidationModule from '@/store/modules/liquidation';
import nakaCollectionModule from '@/store/modules/NakaCollections';
import statsModule from '@/store/modules/stats';
import settlementModule from '@/store/modules/settlements';
import orderKindModule from '@/store/modules/orderKinds';
import withdrawalsModule from '@/store/modules/withdrawals';
import posModule from '@/store/modules/pos';
import paymentGatewayModule from '@/store/modules/paymentsGateway';
import paymentsLinkModule from '@/store/modules/paymentsLink';
import buyersModule from '@/store/modules/buyers';
import country from '@/store/modules/country';
import cashback from '@/store/modules/cashback';
import contactModule from '@/store/modules/contact';
import transactionModule from '@/store/modules/transaction';
import staffPermissions from '@/store/modules/staffPermissions';
import usersApp from '@/store/modules/usersApp';
import resourcesModule from '@/store/modules/resources';
import userStaff from '@/store/modules/userStaff';
import bannerModule from '@/store/modules/banner';
import { RootState } from '@/store/interfaces/rootState';
import RootTypes from '@/store/types/root';
import createPersistedState from 'vuex-persistedstate';
import modalModule from '@/store/modules/modals';
import passwordModule from '@/store/modules/password';
import drafts from '@/store/modules/drafts';
import companyCommerceModule from '@/store/modules/company_commerce';
import permissionGroupModule from '@/store/modules/permissionGroup';
import customerSupportModule from '@/store/modules/customerSupport';
import discount from '@/store/modules/discount';
import discountAndCashbackRules from '@/store/modules/discountAndCashbackRules';
import transfers from '@/store/modules/transfer';
import companyUserModule from '@/store/modules/companyUser';
import { envEvaluation } from '@/config/enviromentEvaluation';
import { allowAny } from 'teip-components';

Vue.use(Vuex);

const state: RootState = {
  startprocess: false,
};
const mutations: MutationTree<RootState> = {
  [RootTypes.mutations.SET_START_PROCESS]: state => {
    state.startprocess = true;
  },
  [RootTypes.mutations.SET_END_PROCESS]: state => {
    state.startprocess = false;
  },
};
const getters: GetterTree<RootState, allowAny> = {
  [RootTypes.getters.GET_START_PROCESS]: state => {
    return state.startprocess;
  },
};
export default new Vuex.Store<RootState>({
  state,
  getters,
  mutations,
  modules: {
    authModule,
    bannerModule,
    cashback,
    commerceModule,
    companyCommerceModule,
    companyModule,
    companyUserModule,
    configurationModule,
    contactModule,
    country,
    buyersModule,
    discount,
    discountAndCashbackRules,
    documentModule,
    drafts,
    intermediariesModule,
    liquidationModule,
    modalModule,
    orderKindModule,
    passwordModule,
    nakaCollectionModule,
    paymentGatewayModule,
    paymentsLinkModule,
    permissionGroupModule,
    posModule,
    resourcesModule,
    settlementModule,
    staffPermissions,
    statsModule,
    customerSupportModule,
    transactionModule,
    transfers,
    uploadImage,
    usersApp,
    userStaff,
    withdrawalsModule,
  },
  plugins: [
    createPersistedState({
      key: 'teip',
      reducer(val: allowAny) {
        if (!val.authModule.token) {
          if (envEvaluation()) {
            configureScope(scope => scope.setUser(null));
          }
          return {};
        }
        if (envEvaluation()) {
          setUser({ id: val.authModule.currentUser.id, });
        }

        return val;
      },
    })
  ],
});
