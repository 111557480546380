



















































import { BranchParams } from '@/store/interfaces/branchs';
import branchs from '@/store/types/branchs';
import commerce from '@/store/types/commerce';
import { tPropsToSearch, tTableColumns, tTeipTableRef } from 'teip-components';
import { mapActions, mapGetters } from 'vuex';
import newBranchModal from './modals/newBranchModal.vue';
import { phoneFormat } from '@/util/phoneFormat';
import Vue from 'vue';

export default Vue.extend({
  name: 'PartnersBranchs',
  components: {
    newBranchModal,
  },
  data: () => ({
    viewModal: false,
    propsToSearch: [] as tPropsToSearch,
    tableColumns: [
      {
        prop: 'name',
        label: 'Sucursal',
        minWidth: 250,
        sortable: true,
      },
      {
        prop: 'address.phone',
        label: 'Teléfono',
        minWidth: 150,
        sortable: true,
        formatter: (value: BranchParams) => {
          return phoneFormat(value.address.phone);
        },
      },
      {
        prop: 'address.description',
        label: 'Dirección',
        minWidth: 250,
        sortable: true,
      },
      {
        prop: 'name',
        label: 'Nombre del encargado',
        minWidth: 350,
        sortable: true,
        formatter: (value: BranchParams) => {
          if (value.contacts.length > 0) {
            return `${value.contacts[0].name} ${value.contacts[0].last_name}`;
          }
          return '';
        },
      },
      {
        prop: 'contacts[0].email',
        label: 'Email del encargado',
        minWidth: 250,
        sortable: true,
      },
      {
        prop: 'users_count',
        label: 'Empleado de sucursal',
        minWidth: 170,
        sortable: true,
      }
    ] as tTableColumns,
    commerce_category: '',
    rowSelected: {
      notification_email: null,
      created_at: '',
      name: '',
      users_count: 0,
      commerce_id: '',
      company_id: '',
      status: 'inactive',
      address: {
        description: '',
        phone: '',
        state_code: '',
        coordinate: {
          latitude: '',
          longitude: '',
        },
      },
      contacts: [],
      naka_branch_configuration: {
        place_id: '',
        id: '',
        language: '',
        source_timezone: '',
      },
    } as BranchParams,
    category_edit: false,
  }),
  computed: {
    ...mapGetters({
      getSingleComerce: `commerceModule/${commerce.getters.GET_SINGLE_COMMERCE}`,
      getListBranchesByCommerce: `commerceModule/branches/${branchs.getters.GET_BRANCH_BY_COMMERCE}`,
    }),
  },
  methods: {
    ...mapActions({
      ListBranchesByCommerce: `commerceModule/branches/${branchs.actions.LIST_BRANCHES_BY_COMMERCE}`,
    }),
    rowselect(row: BranchParams): void {
      this.rowSelected = row;
    },
    branchDetail(): void {
      this.$router.push({
        name: 'users-branch-profile',
        params: {
          sucursal_id: this.$data.rowSelected.id,
          commerce_id: this.$route.params.commerce_id,
        },
      });
    },
    recievedBranch(): void {
      (this.$refs.table as tTeipTableRef).fetchData();
      this.getSingleComerce.branches_count++;
    },

  },
});
