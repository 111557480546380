// Polyfills for js features used in the Dashboard but not supported in some browsers (mainly IE)
import '@/polyfills';
// Notifications plugin. Used on Notifications page
import Notifications from '@/components/Argon/NotificationPlugin';
// Validation plugin used to validate forms
import { configure, extend } from 'vee-validate';
// A plugin file where you could register global components used across the app
import GlobalComponents from './globalComponents';
// A plugin file where you could register global directives
import GlobalDirectives from './globalDirectives';
// Sidebar on the right. Used as a local plugin in DashboardLayout.vue
import SideBar from '@/components/Argon/SidebarPlugin';
// element ui language configuration
import lang from 'element-ui/lib/locale/lang/es';
import locale from 'element-ui/lib/locale';
import 'element-ui/lib/theme-chalk/index.css';

// teip-components
import teipComponents, { allowAny } from 'teip-components';

// vue-bootstrap
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';

// asset imports
import '@/assets/sass/argon.scss';
import '@/assets/css/nucleo/css/nucleo.css';
import * as rules from 'vee-validate/dist/rules';
import { messages } from 'vee-validate/dist/locale/es.json';
import { VueConstructor } from 'vue';
locale.use(lang);

Object.keys(rules).forEach(rule => {
  extend(rule, {
    ...(rules as allowAny)[rule], // copies rule configuration
    message: (messages as allowAny)[rule], // assign message
  });
});
extend('decimals', {
  message: field => `${field} debe ser un número valido.`,
  validate(value, { decimals = '*', separator = '.', }: Record<string, allowAny> = {}) {
    if (value === null || value === undefined || value === '') {
      return {
        valid: false,
      };
    }
    if (Number(decimals) === 0) {
      return {
        valid: /^-?\d*$/.test(value),
      };
    }
    const regexPart = decimals === '*' ? '+' : `{1,${decimals}}`;
    const regex = new RegExp(
      `^[-+]?\\d*(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`
    );

    return {
      valid: regex.test(value),
    };
  },
});
extend('password_rules', {
  message: field => `${field} debe contener una mayúscula|minúscula|número y
     carácteres especiales ej (! @ # $ % ^ & *) y ser mayor a 8 carácteres.`,
  validate: value => {
    const strong_password = new RegExp(
      '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})'
    );
    return strong_password.test(value);
  },
});

extend('decimal_number', {
  message: field => `${field} debe ser un número decimal.`,
  validate(value) {
    return /^-?\d+\.\d+$/.test(value);
  },
});

extend('greater_than', {
  params: ['target'],
  message: (field, { target, }) => {
    return `${field} debe de ser mayor o igual que: ${target}`;
  },
  validate: (value, { target, }: Record<string, allowAny>) => {
    if (value !== 0) {
      return value >= target;
    }
    return true;
  },
});

export default {
  install(Vue: VueConstructor): void {
    Vue.use(teipComponents);
    Vue.use(GlobalComponents);
    Vue.use(GlobalDirectives);
    Vue.use(SideBar);
    Vue.use(Notifications);
    Vue.use(BootstrapVue);
    Vue.use(IconsPlugin);
    configure({
      classes: {
        valid: 'is-valid',
        invalid: 'is-invalid',
        dirty: ['is-dirty', 'is-dirty'], // multiple classes per flag!
      },
    });
  },
};
